import * as Styled from './Dispositions.styled';
import { useState } from 'react';
import DefaultDisposition from './DefaultDisposition';
import NoAnswerDisposition from './NoAnswerDisposition';
import SilentDisposition from './SilentDisposition';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';

const Dispositions = ({
  sendCallDisposition,
}: {
  sendCallDisposition: (disposition: DispositionNames) => void;
}) => {
  const [selectedDisposition, setSelectedDisposition] =
    useState<DispositionNames>(DispositionNames.Normal);

  const cancelDisposition = () => {
    sendCallDisposition(DispositionNames.Normal);
  };

  const getScreen = () => {
    const screen = {
      [DispositionNames.Busy]: <></>,
      [DispositionNames.Illegit]: <></>,
      [DispositionNames.NoAnswer]: (
        <NoAnswerDisposition
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
        />
      ),
      [DispositionNames.Normal]: (
        <DefaultDisposition
          setSelectedDisposition={setSelectedDisposition}
          sendCallDisposition={sendCallDisposition}
        />
      ),
      [DispositionNames.OthLang]: <></>,
      [DispositionNames.SilentAnsMachine]: <></>,
      [DispositionNames.SilentMidCall]: <></>,
      [DispositionNames.SilentNoTimer]: <></>,
      [DispositionNames.SilentTimerRunning]: (
        <SilentDisposition
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
        />
      ),
      [DispositionNames.SpecialTone]: <></>,
    }[selectedDisposition];

    return screen;
  };

  return (
    <Styled.DispositionsContainer>{getScreen()}</Styled.DispositionsContainer>
  );
};
export default Dispositions;
