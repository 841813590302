export enum GatewayEvent {
  authenticate = 'authenticate',
  hello = 'hello',
  ready = 'ready',
  startAudio = 'start_audio',
  captionsEnded = 'captions_ended',
  startCaptions = 'start_captions',
  status = 'status',
  captions = 'captions',
  error = 'error',
  connectionClosed = 'connection_closed',
  alternativesUnavailable = 'alternatives_unavailable',
  readyForCalls = 'ready_for_calls',
  connectingToCall = 'connecting_to_call',
  hangup = 'hangup',
  notAcceptingCalls = 'not_accepting_calls',
  connectionError = 'connection_error',
  audio = 'audio',
  audioEnded = 'audio_ended',
}
export type HangUpEvent = {
  event: GatewayEvent.hangup;
  payload: HangupPayload;
};

export type ConnectingToCallEvent = {
  event: GatewayEvent.connectingToCall;
  payload: ConnectingToCallPayload;
};

type ConnectingToCallPayload = {
  call_id: string;
  emergency_call: boolean;
  emergency_reconnect: boolean;
  near_end_phone: string;
  training_call: boolean;
};

type HangupPayload = {
  call_id: string;
  status: string;
  reason: string;
};