import React, { useEffect } from 'react';
import SILENT from 'assets/icons/volume-minus-icon.svg';
import * as Styled from './Dispositions.styled';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';

interface ISilentDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
}

const SilentDisposition = ({
  setSelectedDisposition,
  cancelDisposition,
}: ISilentDisposition) => {
  const { addCustomCaption } = React.useContext(IPCTSSessionContext);
  const timerSeconds = 60 * 5;

  useEffect(() => {
    addCustomCaption('(Silence)');
  }, []);

  const timerExpired = () => {
    // call useDisposition hook to request ending the call
  };

  const canceledTimer = () => {
    cancelDisposition();
    setSelectedDisposition(DispositionNames.Normal);
  };

  return (
    <Styled.SilentContainer>
      <Styled.SilentDispositionHeaderContainer>
        <Styled.SVGSilentIcon src={SILENT} />
        <Styled.SilentDispositionIconLabel>
          Silent
        </Styled.SilentDispositionIconLabel>
      </Styled.SilentDispositionHeaderContainer>
      <Styled.SilentDispositionLabelContainer>
        <Styled.TextOnly>Call Ending in</Styled.TextOnly>
      </Styled.SilentDispositionLabelContainer>
      <Timer
        seconds={timerSeconds}
        timerExpired={timerExpired}
        canceledTimer={canceledTimer}
        footerText="Cancel if audio resumes"
      />
    </Styled.SilentContainer>
  );
};

export default SilentDisposition;
