import ILLEGITIMATE from 'assets/icons/illegitimate-icon.svg';
import PHONE_CALL from 'assets/icons/phone-call-01.svg';
import LANGUGAGE from 'assets/icons/language-icon.svg';
import NO_ANSWER from 'assets/icons/no-answer-icon.svg';
import SILENT from 'assets/icons/volume-minus-icon.svg';
import HEADPHONES from 'assets/icons/headphones.svg';

import * as Styled from './Dispositions.styled';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';

interface IDefaultDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  sendCallDisposition: (disposition: DispositionNames) => void;
}

const DefaultDisposition = ({
  setSelectedDisposition,
  sendCallDisposition,
}: IDefaultDisposition) => {
  const handleClick = (disposition: DispositionNames) => {
    sendCallDisposition(disposition);
    setSelectedDisposition(disposition);
  };

  return (
    <>
      <Styled.DispositionOption
        id="Busy"
        onClick={() => handleClick(DispositionNames.Busy)}
      >
        <Styled.SVGIcon src={PHONE_CALL} />
        <Styled.ButtonText>Busy</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Illegit"
        onClick={() => handleClick(DispositionNames.Illegit)}
      >
        <Styled.SVGIcon src={ILLEGITIMATE} />
        <Styled.ButtonText>Illegitimate</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Language"
        onClick={() => handleClick(DispositionNames.OthLang)}
      >
        <Styled.SVGIcon src={LANGUGAGE} />
        <Styled.ButtonText>Language</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="NoAnswer"
        onClick={() => handleClick(DispositionNames.NoAnswer)}
      >
        <Styled.SVGIcon src={NO_ANSWER} />
        <Styled.ButtonText>No Answer</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Silent"
        onClick={() => handleClick(DispositionNames.SilentTimerRunning)}
      >
        <Styled.SVGIcon src={SILENT} />
        <Styled.ButtonText>Silent</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="SitTone"
        onClick={() => handleClick(DispositionNames.SpecialTone)}
      >
        <Styled.SVGIcon src={HEADPHONES} />
        <Styled.ButtonText>Special Tone</Styled.ButtonText>
      </Styled.DispositionOption>
    </>
  );
};

export default DefaultDisposition;
